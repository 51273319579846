/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
// https://www.usegood.work/ for hamburger modal
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import useToggle from '../hooks/use-toggle';
import { useScrollPosition } from '../hooks/use-scroll-percentage';
import { HamburgerButton } from './header';
import { Email } from './social';

const Layout = ({ children, ...rest }) => {
  const [displayMenu, toggleDisplayMenu] = useToggle(false);
  const [pageRef, position, percentage] = useScrollPosition();
  const [menuVisibleOnScroll, setMenuVisibleOnScroll] = useState(true);

  useEffect(() => {
    const criteria = percentage > 0;
    if (criteria !== menuVisibleOnScroll) {
      setMenuVisibleOnScroll(criteria);
    }
    document.querySelector('#progress').style.setProperty('--scroll', percentage * 100 + '%');
  }, [position, percentage, menuVisibleOnScroll]);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          version
        }
      }
    }
  `);

  return (
    <>
      <div
        ref={pageRef}
        className="bg-gray-100 font-sans leading-normal tracking-normal flex flex-col"
        style={{ minHeight: '100vh' }}
      >
        <nav
          id="header"
          className={`fixed w-full z-10 top-0 ${
            menuVisibleOnScroll || displayMenu ? 'bg-white shadow' : 'bg-gray-100'
          }`}
        >
          <div id="progress"></div>

          <div className="w-full md:max-w-4xl mx-auto flex flex-wrap items-center justify-between mt-0 py-3">
            <div className="pl-4">
              <Link
                className="text-blue-600 text-base no-underline hover:no-underline font-extrabold text-xl"
                to="/"
              >
                <span role="img" aria-label="hand">
                  🏊‍♂️
                </span>
                {data.site.siteMetadata.title}
              </Link>
            </div>

            <div className="block lg:hidden pr-4">
              <HamburgerButton onClick={toggleDisplayMenu} selected={displayMenu} />
            </div>

            <div
              className={`w-full flex-grow lg:flex lg:items-center lg:w-auto lg:block mt-2 lg:mt-0 md:bg-transparent z-20 
            ${displayMenu ? '' : ' hidden'}`}
              id="nav-content"
            >
              <ul className="list-reset lg:flex justify-end flex-1 items-center">
                <li className="mr-3">
                  <Link
                    className="inline-block py-2 px-4 text-gray-900 font-bold no-underline"
                    to="/#articles"
                    onClick={toggleDisplayMenu}
                  >
                    Articles
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* <!--Container--> */}
        <div className="container w-full md:max-w-3xl mx-auto pt-10 flex-grow">
          <div
            className="w-full px-4 md:px-6 text-base md:text-xl text-gray-800 leading-normal"
            style={{ fontFamily: 'Georgia,serif' }}
          >
            {children}
          </div>
        </div>
        {/* // </Layout> */}

        <footer className="bg-white border-t border-gray-400 shadow ">
          <div className="container max-w-4xl mx-auto flex py-10">
            <div className="w-full mx-auto flex flex-wrap">
              <div className="flex w-full md:w-1/2 ">
                <div className="px-8">
                  <h3 className="font-bold text-gray-900 mt-0">A propos</h3>
                  <p className="py-4 text-gray-600 text-sm">
                    <strong>Site personnel relatif à la piscine.</strong>.
                  </p>
                </div>
              </div>

              <div className="flex w-full md:w-1/2">
                <div className="px-8">
                  <h3 className="font-bold text-gray-900 mt-0">Social</h3>
                  <ul className="list-reset items-center text-sm pt-3">
                    <li className="md:my-0 my-4">
                      <Email
                        icon
                        className="inline-block text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-1"
                      >
                        Contact
                      </Email>
                    </li>
                    {/* <li className="md:my-0 my-4">
                      <Twitter className="inline-block text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-1" />
                    </li>
                    <li className="md:my-0 my-4">
                      <LinkedIn className="inline-block text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-1" />
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="text-white bg-gray-800 text-center p-5 text-sm">
            © {new Date().getFullYear()}, Mission Piscine · Tous droits réservés ·{' '}
            {data.site.siteMetadata.version} ·{' '}
            <Link className="second-footer-links" to="/privacy-statement">
              Politique de confidentialité
            </Link>
          </div>
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
