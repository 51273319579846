import { useRef, useLayoutEffect, useState, useEffect } from "react"

const isBrowser = typeof window !== `undefined`

function getScrollPosition(scrollRef) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = scrollRef.current ? scrollRef.current : document.body
  const currentPosition = target.getBoundingClientRect()
  const clientHeight = document.documentElement.clientHeight
  return {
    y: currentPosition.top,
    py: Math.abs(currentPosition.top / (currentPosition.height - clientHeight)),
  }
}

export function useScrollPosition() {
  const scrollRef = useRef(null)
  const [position, setPosition] = useState(NaN)
  const [percentage, setPercentage] = useState(NaN)

  // Initial value
  const handleScroll = () => {
    const scrollPosition = getScrollPosition(scrollRef)
    setPosition(scrollPosition.y)
    setPercentage(scrollPosition.py)
  }

  useEffect(() => {
    // First call
    handleScroll()
  }, [scrollRef])

  // handleScroll();

  useLayoutEffect(() => {
    if (!isBrowser) {
      return
    }

    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return [scrollRef, position, percentage]
}

useScrollPosition.defaultProps = {}
